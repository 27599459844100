import React from "react"
import Layout from "../components/Layout"
import utils  from "../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div style={{color: 'black', marginTop: '4.75em'}} className="inner">
              <FontAwesomeIcon className="d-inline" icon={utils.icon('seedling')} size="4x" />
              <h1 className="d-inline text-black mb-2">NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
